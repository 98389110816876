.column {
  background-color: #212529;
  border-radius: 0px;
  border: 1px solid #495057;
  text-align: center;
  color: #dee2e6;
  font-size: 125%;
}

.column input {
  color: #dee2e6;
}

.curve-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.curve-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (min-width:768px) {
  .player-name {
    height: 50px;
  }
}

@media (min-width:992px) {
  .player-name {
    height: 100px;
  }

  .pods-button-left {
    padding-right: 0px;
  }

  .pods-button-right {
    padding-left: 0px;
  }
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}